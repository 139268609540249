* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.App {
  text-align: center;
  background-image: url('../public/background.png');
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
 
}
